import { useNavigate, useLocation } from 'react-router-dom';
import  Button  from './Components/button';
import { MdError } from 'react-icons/md' ;


function ErrorPage() {
    const {state} = useLocation();
    const navigate = useNavigate();
    var message;
    if(state){
        var { message } = state; // Read values passed on state
    } else {
        const params = new URLSearchParams(window.location.search);
        if(params.has("msg")){
            message = params.get("msg");
        }
        else{
            message = "Unknown Error";
        }
    }
    return ( <div class="flex flex-col h-full align-middle justify-center">
                <div class="flex align-middle justify-center"><MdError size="140px" class="text-red-600"/></div>
                <div class="text-3xl m-10 text-red-900">{message}</div>
                <Button value="Return to main page" className="flex flex-initial h-8 p-4" onClick={() => navigate('/')}/>
             </div> );
}




export default ErrorPage