import Webcam from "react-webcam";
import { AiFillVideoCamera } from "react-icons/ai";

function CustomerWebcam() {
    const videoConstraints = {
        width: { min: 200 , max: 300 },
        //aspectRatio: 0.6666666667,
        facingMode: "user"
      };

    return (
        <div className="bg-gradient-to-r lg:rounded-3xl m-2 from-slate-500">
            <Webcam className="rounded-3xl opacity-75" videoConstraints={videoConstraints}/>
            <div className="flex justify-center text-xs animate-pulse">
                <AiFillVideoCamera className="mx-2 text-red-800" />
                CCTV In Operation
            </div>
        </div>
    )
}

export default CustomerWebcam;