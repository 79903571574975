
import { AiOutlineLoading } from 'react-icons/ai';

export function loading(text) {
    return (
        <div className="flex flex-row justify-center">
            <span >
                <AiOutlineLoading className="animate-spin mx-3"/>
            </span>{text ? text : ""}
        </div>
    )
}