import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loading } from "./Components/loading";
import { useEffect, useState, useRef } from "react";
import {TiTickOutline} from "react-icons/ti";
import { sendFulfillOrder } from "./services/orders";
import { MdError } from 'react-icons/md' ;
import Button from "./Components/button";

function FulfillOrder() {
    let [nextStage, setNextStage] = useState(undefined);
    let [success, setSuccess] = useState(false);
    let [icon, setIcon] = useState(loading());
    let [iconColour, setIconColour] = useState("text-gray-600");
    let [stageDescription, setStageDescription] = useState("Fulfilling order, please wait...");
    let order = useSelector((state) => state.account.order);
    let authenticatedOrder = useSelector((state) => state.account.authenticatedOrder);
    const navigate = useNavigate();
    let timeout = useRef(0);

    useEffect(() => {
        sendFulfillOrder(order, authenticatedOrder)
        .then(result => {
                            clearTimeout(timeout.current);
                            timeout.current = setTimeout(() => { navigate('/'); }, 120000);  
                            if(result.success) {
                                setSuccess(true);
                                setIcon(<TiTickOutline/>);
                                setStageDescription("Order fulfilled!");
                                setIconColour("text-green-600");
                                setNextStage(result.redirect);
                            }
                            else {
                                setIcon(<MdError />);
                                setStageDescription("Failed! Please try again");
                                setIconColour("text-red-600");
                                setNextStage(result.redirect);
                            }
                        }
        )
    }, [])
    useEffect(() => () => {clearTimeout(timeout.current);}, []);
    return (
        <div className="flex flex-col h-full w-full grow items-center justify-center align-middle border">
            <div className="flex flex-col text-xl shadow-sm m-5 p-20 text-center">
                <div className={iconColour + " self-center m-5 items-center text-6xl justify-center"}>{icon}</div>
                <div className="text-center text-3xl items-center text-gray-800">{stageDescription}</div>
                { success ? <div class=" text-xs md:text-xl p-1 text-slate-700 font-sans">Thank you for collecting your order.</div> : ""}
                { success ? <div class=" text-xs md:text-lg p-1 italic text-slate-500 font-sans">Please ensure the garage is locked.</div> : ""}
                { nextStage ? <Button value="Continue" className="text-xs m-5 self-center md:text-lg w-52 h-10 bg-slate-600 text-white" onClick={() => navigate('/')}></Button> : ""}
                
            </div>
        </div>
    )
}


function WithNavigate(props) {
    let navigate = useNavigate();
    return <FulfillOrder navigate={navigate} />
  }
  export default WithNavigate;