import { useSelector } from "react-redux";


function InputField ({center, key, placeholder, value}) {
    const css = (center ? ' text-center ' : ' text-left ') + " inline-flex h-full w-full justify-end gap-x-1.5 rounded-md bg-white px-2 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 pointer-events-none"
    return ( 
        <input key={key} 
            value={value}
            placeholder={placeholder}
            className={ css } 
            readOnly />
    );
}

export default InputField;