//import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { LeftIcon, RightIcon } from "./Components/side_icon";
import { getList } from "./services/orders";
import { loading } from "./Components/loading";
import { useNavigate } from "react-router-dom";
import { MdError } from 'react-icons/md' ;
import { clearAuthenticatedOrder, setAuthenticatedOrder } from './redux/ducks/accountSlice';
import Button from "./Components/button";
import { useCountdown } from './Components/useCountdown';

function DisplayOrder() {
    const waitTimer = 120000 // 120seconds, 2minutes
    //let { orderNumber } = useParams();
    let searchOrder = useSelector((state) => state.account.order);
    //let userInitials = useSelector((state) => state.account.initials);
    let authenticatedOrder = useSelector((state) => state.account.authenticatedOrder);
    let [name, setName] = useState(loading("Loading"));
    let [sideIcon, setSideIcon] = useState(loading("size=100px"));
    let [order, setOrder] = useState("");
    let [email, setEmail] = useState("");
    let [fulfillmentStatus, setFulfillmentStatus] = useState("");
    let [initials, setInitials] = useState(loading());
    let [loaded, setLoaded] = useState(false);
    let [correctSalt, setCorrectSalt] = useState("");
    let [productName, setProductName] = useState("");
    let [quantity, setQuantity] = useState("");
    let [timer, setTimer] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let timeout = useRef(0);
    

    const [days, hours, minutes, seconds] = useCountdown(timer);

    const getInitials = (name) => {
        var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    useEffect(() => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => { navigate('/fulfill-order'); }, waitTimer);  
        setTimer(new Date().getTime() + waitTimer);
        dispatch(clearAuthenticatedOrder());
        getList(searchOrder, "")
         .then(items => {
            if(items.order){
                dispatch(setAuthenticatedOrder({authenticatedOrder : items.id}));
                setOrder(items.order);
                setName(items.customer);
                setEmail(items.email);
                setFulfillmentStatus(items.fulfillment_status);
                setInitials(getInitials(items.customer));
                setCorrectSalt(items.expectedSalt === 'Unknown' ? '' : items.expectedSalt);
                setProductName(items.items[0]['name'].replace('Customer Collection Only',''));
                setQuantity(items.items[0]['quantity']);
                setSideIcon(items.items[0]['name'].includes('Curve') ? "Left" : "Right")
                setLoaded(true);
            }
            else
            {
                navigate("/Error", {state: {message: "Cannot find order "+order+", please confirm the order number and try again.", order: order}});
            }
            },
         (TypeError) => { navigate("/Error", { state: { message: "Error contacting the server, try again later", order: order } }) })
      return () => {
        
      }
    }, [])
    useEffect(() => () => {clearTimeout(timeout.current);}, []);

    if(fulfillmentStatus =='fulfilled'){
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => { navigate('/'); }, waitTimer);  
    }
    return ( <div class="flex font-sans flex-row h-full w-full gap-5 px-5 mx-5 justify-center items-center align-middle ">
                <div class="flex flex-col align-top items-top py-10 px-10 h-[90%] w-[98%] md:w-[90%] hover:shadow-xl shadow-2xl bg-gray-100 rounded-md">
                    <div class="flex flex-row py-2">
                        <div class="flex rounded-full h-20 w-20 font-bold justify-center align-center items-center bg-green-800 text-white text-4xl" >{initials}</div>
                        <div class="flex flex-col grow justify-center p-5">
                        <div class="font-bold" >{name}</div>
                        <div class="text-gray-400 text-sm" >{email}</div>
                            <div class="justify-center text-gray-400 italic text-sm" >Order #{order}</div>
                        </div>
                        <div class={(productName.toLowerCase().includes(correctSalt.toLowerCase()) | correctSalt.length === 0 ? "hidden" : "") + " flex animate-pulse w-[60%] m-3 items-center align-middle justify-center text-sm md:text-md rounded-xl shadow-2xl border py-2"}>
                            <MdError size="70px" className="text-red-600 mx-2"/> This purchase is for {productName.split('Salt')[0]+" Salt"} however our records show your softner requires {correctSalt}, please contact us for more information
                        </div> 
                    </div>
                {/* </div> */}
                {/* <div class="flex flex-col items-left justify-center p-5 h-[90%] w-[50%] hover:shadow-xl shadow-2xl bg-gray-100"> */}
                <div className={(fulfillmentStatus == 'fulfilled' ? "" : "hidden") + " flex text-red-700 text-xs md:text-lg justify-center w-[100%]"}>
                        The order has already been collected, please contact us or select another order
                </div>
                       
                <div class={(loaded ? (fulfillmentStatus == 'fulfilled' ? "hidden" : ""): "hidden") + " text-xs md:text-xl p-1 text-center text-slate-700 font-sans"}>
                    <div className={(loaded ? "": "hidden") + " flex justify-center w-[100%]"}>
                        Please collect &nbsp;<b>{quantity}</b>&nbsp;of&nbsp;<b>{productName}</b>
                    </div>
                    <div class={(loaded ? "": "hidden") + " flex justify-center items-center align-middle grow p-3"} >
                        {sideIcon === 'Left' ? <LeftIcon /> : <RightIcon />}
                    </div>
                    <div class={(loaded ? "": "hidden") + (sideIcon === 'Left' ? 'text-blue-800' : 'text-red-800') +" flex  animate-pulse justify-center text-xl font-bold items-center align-middle grow"}>
                        Please collect your salt from the {(sideIcon === 'Left' ? 'BLUE' : 'RED')} pallet.
                    </div>
                </div>
                
                <div class={(loaded ? "" : "hidden") + " flex flex-row items-center justify-center gap-1 my-3"}> 
                <Button value={"Confirm collection"} className={(fulfillmentStatus == 'fulfilled' ? "hidden" : "") +" text-xs md:text-lg p-5 w-30 md:w-60  h-10 bg-green-500 text-white"} onClick={() => navigate('/fulfill-order')} />
                <Button value={(fulfillmentStatus == 'fulfilled' ? "Select another order" : "Cancel collection")} className="text-xs md:text-lg p-5 w-30 md:w-60 h-10 bg-red-500 text-white" onClick={() => navigate('/')} />
                </div>
                <div className={(loaded ? "" : "hidden") + " text-xs md:text-sm mb-5 text-gray-600 items-center text-center justify-center "}>
                    Collection will be automatically confirmed in {String(minutes).padStart(2, '0')+":"+String(seconds).padStart(2, '0')}
                </div>
            </div>
                {/* <DelayNavigate to={"/"} delay={120000} ></DelayNavigate> */}
            </div> );
}

 
export default DisplayOrder;
