import Clock from './clock'
import logo from '../assets/shires_water_logo.webp';
import { useNavigate } from "react-router-dom";
import { MdHelp } from 'react-icons/md';

function Header () {
    let navigate = useNavigate();
    const goToHome = () => {
        navigate("/");
    };
    return (
        <div className="flex flex-row w-screen top-0 text-slate-700 h-14 bg-white rounded-s mb-3 shadow-lg">
            <div className="flex left-0 h-14 p-2 w-full font-normal 
                        text-lg items-center cursor-pointer" onClick={goToHome}><img className="object-scale-down h-14 p-2" alt="Graham Carrol Heating Services" src={logo}></img>
            </div>
            <div className="flex w-full"></div>
            <div className="flex right-0 p-5 font-medium justify-end text-sm 
                        items-center text-right gap-5">
                <div className="cursor-pointer text-xl" onClick={() => navigate("/Help")}>Help</div>
                <MdHelp className="h-10 cursor-pointer" size="30px" onClick={() => navigate("/Help")}/>
                <Clock />
            </div>
        </div>
    )
}

export default Header;