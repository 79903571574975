import { createSlice } from '@reduxjs/toolkit'

const accountSlice = createSlice({
  name: 'account',
  initialState: {order : '', initials : '', authenticatedOrder : 0},
  reducers: {
    clearAll: (state, action) => {
      return {
        ...state,
        order : '',
        initials : '',
        authenticatedOrder : 0
      }
    },
    setOrder: (state, action) => {
      return {
        ...state,
        order : action.payload.order
      }
    },
    clearOrder : (state) => {
      return {
        ...state,
        order : ''
      }
    },
    setInitials : (state, action) => {
      return {
        ...state,
        initials : action.payload.initials
      }
    },
    clearInitials : (state, action) => {
      return {
        ...state,
        initials : ''
      }
    },
    setAuthenticatedOrder : (state, action) => {
      return {
        ...state,
        authenticatedOrder : action.payload.authenticatedOrder
      }
    },
    clearAuthenticatedOrder : (state, action) => {
      return {
        ...state,
        authenticatedOrder : 0
      }
    }
  }
})

export const { clearAll, setOrder, clearOrder, setInitials, clearInitials, setAuthenticatedOrder, clearAuthenticatedOrder } = accountSlice.actions
export default accountSlice.reducer