import { BsFillArrowRightSquareFill  } from 'react-icons/bs';
import { BsFillArrowLeftSquareFill  } from 'react-icons/bs';
import { MdPallet } from 'react-icons/md';

export function LeftIcon () {
    return ( 
        <BsFillArrowLeftSquareFill className="fill-blue-800 animate-leftbounce" size="80px" color="green" />
        //<MdPallet className="fill-blue-800 animate-pulse" size="80px" />
     );
}

export function RightIcon () {
    return ( 
        <BsFillArrowRightSquareFill className="fill-red-800 animate-rightbounce" size="80px" color="green" />
        //<MdPallet className="fill-red-800 animate-pulse" size="80px" color="green" />
     );
}
