var constants = require('./constants');

export async function sendFulfillOrder(orderId, authenticatedOrder) {
  const response = await fetch(constants.BACKEND + "/API/fulfillOrder/"+orderId, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({authenticatedOrder: authenticatedOrder})
  });
  const data = response.json();
  return data;
}

export async function  getList(orderId, initials) {
    const response = await fetch(constants.BACKEND + '/API/getOrder/'+orderId, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({initials: initials})
    });
    const data = response.json();
    return data;
  }

export async function reduceStocks(product, quantity) {
  try {
    const response = await fetch(constants.BACKEND + '/API/reduceStock/'+product+'?quantity='+quantity,
                                  {credentials: "include"});
    if(response.json.length > 0){
      const data = response.json();
      return data;
    }
    return undefined;
  }
  catch (err)
  {
    return undefined;
  }
}