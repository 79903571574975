import Header from './Components/header';
import './App.css';
import CustomerWebcam from './Components/webcam';
import OrderForm from './OrderFormView';
import InitialsForm from './InitialsFormView';
import Override from './OverrideView';
import ErrorPage from './ErrorView';
import FulfillOrder from './OrderFulfillment';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DisplayOrder from './DisplayOrderView';
import Fnf404 from './Fnf404View';
import DisplayHelp from './Help';
import { Provider } from 'react-redux';
import { store } from './redux/configureStore.js';

function App() {
  const minimumWidthCamera = 800
  return (
    <div className="flex h-screen w-screen flex-col justify-start items-center">
      <Provider store={store}>
        <BrowserRouter>
        <Header></Header>
        <div className="flex flex-row h-screen w-screen">
          {
            // If viewport is of a mobile device
            window.innerWidth <= minimumWidthCamera ? 
                  <div className="flex"></div>
                :  
                  <div className="flex flex-col-reverse">
                    <CustomerWebcam />
                  </div>
          }
          
          <div className={window.innerWidth <= minimumWidthCamera ? "h-[90%] w-full" : "h-90% w-3/5"}>
              <Routes>
                  <Route exact path="/" element={<OrderForm  class="h-full w-full bg-teal-400" />} />
                  <Route path="/specify-initials" element={<InitialsForm />} />
                  <Route path="/display-order" element={<DisplayOrder />} />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="/override" element={<Override />} />
                  <Route path="/fulfill-order" element={<FulfillOrder />} />
                  <Route path="/help" element={<DisplayHelp />} />
                  <Route path="*" element={<Fnf404 />} />
                  {/* <Route path="/Directory/:id" component={Profile} /> */}
                </Routes>
          </div>
        </div>
          </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
