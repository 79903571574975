import {Component} from 'react';


class Button extends Component {
    render() { 
        var css = this.props.className;
        return (  
            <div onClick={this.props.onClick} className={css + " self-center text-center flex rounded-lg items-center cursor-pointer justify-center drop-shadow-2xl bg-gradient-to-b text-gray-100 from-gray-600 to-gray-800"}>
                {this.props.value}
            </div>
        );
    }
}
 
export default Button;