import Keyboard from './Components/keyboard';
import Input from './Components/input'
import Button from './Components/button';
import { useEffect } from 'react';
import { redirect, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setInitials, clearInitials } from './redux/ducks/accountSlice';
import { login } from './services/login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emitMessage from './Components/popup';

function InitialsForm () {
  const dispatch = useDispatch();
  let initials = useSelector((state) => state.account.initials);
  const navigate = useNavigate();  
  const updateInitials = (letter) => {
    let newInitials = ''
    if(letter === '←') {
      newInitials = initials ? initials.slice(0,-1) : initials;
    }
    else {
      newInitials = initials ? initials + letter : letter;
    }
    dispatch(setInitials({initials : newInitials}));
  }

  const getNextAction = async (code) => {
    if (code.charAt(0) == '*'){
      const data = await login(code);
      if(data.hasOwnProperty('redirected')){
        navigate(data['url']);
      }
      else if(data.hasOwnProperty('login'))
      {
        emitMessage("Logged in successfully",data.login);
      }
    }
    else{
      navigate('/display-order');
    }
  }

  useEffect(() => {
    dispatch(clearInitials());
  }, []);

  //const validInitials = new RegExp('([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))?[0-9][A-Za-z]{2})');
  const validInitials = new RegExp('[*]*[a-zA-Z]{2}')
  return (
    <div className="flex h-full flex-col justify-center items-center">
      <div className={"text-slate-700 w-full font-sans justify-end px-3 items-end align-bottom text-base"}>Please enter your initials (First and Last name)</div>
      <div className="flex p-2 flex-row justify-center font-sans lg:text-3xl gap-2 md:text-2xl sm:text-2xl items-center">
        <Input placeholder="Enter Initials (First and Last Name)" value={initials} center={true} editable='False'></Input>
        <Button className={"text-md sm:text-xl " + (validInitials.test(initials) ? "text-slate-100 p-4 from-green-600 to-green-500" : "from-slate-400 to-slate-300 text-slate-500 p-4 ")} 
        value="Find My Order" onClick={validInitials.test(initials) ? () => getNextAction(initials) : () => {}}/>
      </div>
      <div className="flex align-middle items-centre w-full h-2/3 bg-slate-600 rounded-md"><Keyboard numericOnly={false} updateInput={updateInitials} /></div>
      <ToastContainer />
    </div>
  );
}
 
function WithNavigate(props) {
  let navigate = useNavigate();
  return <InitialsForm  navigate={navigate} />
}
export default WithNavigate;
