import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdClear } from 'react-icons/md';

import { Dropdown } from "./Components/dropdown";
import InputField  from "./Components/input";
import Keyboard from "./Components/keyboard";
import Button from "./Components/button";
import { reduceStocks } from "./services/orders";
import emitMessage from "./Components/popup";
import { getProducts } from "./services/products";


function Override() {
    let [product, setProduct] = useState("Please select a product");
    let [productId, setProductId] = useState(-1);
    let [productImg, setProductImg] = useState("");
    let [productQty, setProductQty] = useState(-1);
    let [productPrice, setProductPrice] = useState("00.00");
    let [productList, setProductList] = useState([]);
    let [quantity, setQuantity] = useState(0);
    
    function clearProductSelection(){
        setProduct("Please select a product");
        setProductId(-1);
        setQuantity(0);
    }

    function selectProduct(product){
        setProduct(product.text);
        setProductId(-1);
        for(var prod in productList){
            if(productList[prod]['id'] === product.id){
                setProductImg(productList[prod]['image']);
                setProductQty(productList[prod]['quantity']);
                setProductPrice(productList[prod]['price']);
            }
        }
        setProductId(product.id);
    }

    async function updateStocks() {
        const response = await reduceStocks(productId, quantity);
        console.log(response);
        if(response.id){
            const data = response;
            emitMessage("Updated stock levels for "+product+" (stocks at "+data.quantity+")", 
                        data.status);
            clearProductSelection();
        }
        else{
            emitMessage("Error reducing stock levels for "+product, "error");
        }
        
    }

    const updateQuantity = (letter) => {
        if(letter === '←') {
            setQuantity(quantity ? quantity.slice(0,-1) : quantity);
        }
        else if(letter !== '*') {
            setQuantity(quantity ? quantity + letter : letter);
        }
    }

    const navigate = useNavigate();
    useEffect(() => {
        getProducts()
         .then(items => {
            if(items.length > 0){
                var options = [];
                for(var item in items){
                    var title = items[item]['title'];
                    title = title.replace("Customer Collection Only","Collection");
                    title = title.replace(" (2 blocks per pack)","");
                    options.push({'text': title, 
                                  'image' : items[item]['image'],
                                  'id': items[item]['id'], 
                                  'price': items[item]['price'],
                                  'quantity': items[item]['quantity']});
                }
                setProductList(options);
            }
            else
            {
                navigate("/Error", {state: {message: "Cannot find order, please confirm the order number and try again.", }});
            }
            },
            (TypeError) => { navigate("/Error", { state: { message: "Error contacting the server, try again later",  } }) })
      return () => {
        
      }
    }, []);

    return ( 
        <div class="flex flex-col text-lg w-[90%] h-[80%] p-2 rounded-md align-top items-center bg-slate-400">
            <div class="flex flex-row  align-middle items-center w-[90%] p-2 whitespace-pre-wrap">
                <div class="flex w-[80%] flex-wrap">Select the product you are collecting</div>
                {productId === -1 ? <div class="flex grow"><Dropdown dropdownText={product} css="" callback={selectProduct} values={productList}></Dropdown></div>
                                :
                                <div class={(productId > 0 ? "" : "hidden ") + "flex flex-row py-2 px-4 text-xs border-2 rounded-lg bg-white "}>
                                    <div className="flex content-center items-center text-center align-middle h-14 w-14">
                                        {productImg ? <img src={productImg} class="flex object-contain h-14" alt="Product"></img> : <div class="rounded-full animate-pulse  bg-slate-200 h-10 w-10"></div>}
                                    </div>
                                    <div className="flex flex-col px-5 text-slate-800">
                                        <div>Name: { product }</div>
                                        <div>Price: £{ productPrice }</div>
                                        <div>Quantity: { productQty }</div>
                                    </div>
                                    <div className="items-end text-right cursor-pointer" onClick={() => clearProductSelection()}><MdClear className="text-red-900 text-lg"></MdClear></div>
                                </div>
                }
            </div>
            <div class="flex flex-row align-middle items-center w-[90%] p-2 whitespace-pre-wrap">
                <div class="flex w-[80%]">Insert Quantity of stock to decrease</div>
                <div class="flex grow"><InputField class="" center value={quantity} /></div>
            </div>
            <div className="flex h-[90%] flex-row text-xs align-top items-center w-[90%] p-2 whitespace-pre-wrap">
                <Keyboard numericOnly={true} updateInput={(value) => updateQuantity(value)}/>
            </div>
            <div className="flex flex-row text-lg space-x-4 align-top items-center w-[90%] p-2 whitespace-pre-wrap"> 
            <Button value="Update Stocks" onClick={() => updateStocks()} className="w-full text-white " />
            <Button value="Clear" onClick={() => clearProductSelection()} className="w-full text-white bg-red-800 " />
            </div>
            <ToastContainer />
        </div>
    );
}

export default Override;