import React from 'react';
import Key from './key';

class Keyboard extends React.Component {
  
    triggerParent(letter) {
      this.props.updateInput(letter)
    }
  
    render() {
      var line1 = [];
      var line2 = [];
      var line3 = [];
      var line4 = [];

      if (this.props.numericOnly == true) {
        line1 = ['7', '8' , '9'];
        line2 = ['4', '5' , '6'];
        line3 = ['1', '2' , '3'];
        line4 = ['*',  '0' , '←'];
      }
      else {
        line1 = ['1','2','3','4','5','6','7','8','9','0','*','←'];
        line2 = ['Q','W','E','R','T','Y','U','I','O','P','+',','];
        line3 = ['A','S','D','F','G','H','J','K','L','-','_',':'];
        line4 = ['%','Z','X','C','V','B','N','M','.','@','£','#'];
      }
      
    return ( 
        <div className="flex flex-col m-3 align-middle justify-center items-center rounded-lg w-full h-full object-fill">
          <div className="flex flex-row gap-2 p-2 w-[80%] h-[10%] sm:h-[20%] min-w-full">
              {line1.map(letter => (  
                <Key key={letter} letter={letter} onClick={() => this.triggerParent(letter)}></Key>  
              ))}  
          </div>
          <div className="flex flex-row gap-2 p-2 w-[80%] h-[10%] sm:h-[20%] min-w-full">
              {line2.map(letter => (  
                <Key key={letter} letter={letter} onClick={() => this.triggerParent(letter)}></Key>
              ))}  
          </div>
          <div className="flex flex-row gap-2 p-2 w-[80%] h-[10%] sm:h-[20%] min-w-full">
              {line3.map(letter => (  
                <Key key={letter} letter={letter} onClick={() => this.triggerParent(letter)}></Key>
              ))}  
          </div>
          <div className="flex flex-row gap-2 p-2 w-[80%] h-[10%] sm:h-[20%] min-w-full">
              {line4.map(letter => (  
                <Key key={letter} letter={letter} onClick={() => this.triggerParent(letter)}></Key>  
              ))}  
          </div>
        </div>);
    }
  }

  export default Keyboard 
