import Keyboard from './Components/keyboard';
import Input from './Components/input'
import Button from './Components/button';
import { useEffect } from 'react';
import { redirect, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setOrder, clearAll } from './redux/ducks/accountSlice';
import { login } from './services/login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emitMessage from './Components/popup';

function OrderForm () {
  const minimumWidthKeyboard = 600
  const dispatch = useDispatch();
  let order = useSelector((state) => state.account.order);
  const navigate = useNavigate();  
  const updateOrder = (letter) => {
    let newOrder = ''
    if(letter === '←') {
      newOrder = order ? order.slice(0,-1) : order;
    }
    else {
      newOrder = order ? order + letter : letter;
    }
    dispatch(setOrder({order : newOrder}));
  }

  const getNextAction = async (code) => {
    if (code.charAt(0) == '*'){
      const data = await login(code);
      if(data.hasOwnProperty('redirected')){
        navigate(data['url']);
      }
      else if(data.hasOwnProperty('login'))
      {
        emitMessage("Logged in successfully",data.login);
      }
      dispatch(setOrder({order : ""}));
    }
    else{
      navigate('/display-order');
    }
  }

  useEffect(() => {
    dispatch(clearAll());
  }, []);

  //const validOrder = new RegExp('([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))?[0-9][A-Za-z]{2})');
  const validOrder = new RegExp('[*]*([0-9]{4}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})')
  return (
    <div className="flex w-full h-full flex-col justify-center items-center">
      <div className={"text-slate-700 w-full font-sans justify-end px-3 items-end align-bottom text-md md:text-2xl"}>Please enter order number or e-mail address</div>
      <div className="flex m-2 w-full flex-row justify-center font-sans lg:text-3xl gap-2 md:text-2xl sm:text-sm items-center">
        <Input placeholder="Order Number/E-mail" value={order} center={true} editable='False'></Input>
        <Button className={"text-sm md:text-xl " + (validOrder.test(order) ? "text-slate-100 p-4 from-green-600 to-green-500" : "from-slate-400 to-slate-300 text-slate-500 p-4 ")} 
        value="Find My Order" onClick={validOrder.test(order) ? () => getNextAction(order) : () => {}}/>
      </div>
      <div className="flex align-middle items-centre w-full h-2/3 bg-slate-600 rounded-md text-xs md:text-lg"><Keyboard numericOnly={ window.innerWidth < minimumWidthKeyboard ? true : false} updateInput={updateOrder} /></div>
      <ToastContainer />
    </div>
  );
}
 
function WithNavigate(props) {
  let navigate = useNavigate();
  return <OrderForm  navigate={navigate} />
}
export default WithNavigate;
