import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function emitMessage(message, status='success') {
    if(message.length === 0){
        message = "Error";
    }
    switch(status){
        case 'error':
            toast.error(message, {position: "top-right", theme: "colored"});
            break;
        default:
            toast.info(message, {position: "top-right", theme: "colored"});
            break;
    }
}

export default emitMessage;