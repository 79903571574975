import React from 'react'

class Key extends React.Component {
    render () {
        const letters = new RegExp("^[a-zA-Z]$");
        const digits = new RegExp("^[0-9]$");
        const symbols = new RegExp("^[@_£#:,.*%+-]$");
        let colour = "bg-gray-600";
        if(letters.test(this.props.letter)) {
            colour = "bg-gray-500";
        }
        else if(digits.test(this.props.letter)) {
            colour = "bg-gray-500";
        }
        else if(!symbols.test(this.props.letter)) {
            colour = "bg-gray-700"
        }
        return (
                <div className={"flex justify-center items-center rounded-md text-white \
                            hover:bg-green-600 border-gray-600 bg-blend-color-burn \
                            cursor-pointer shadow-2xl grow text-[180%] h-18 w-12 " + colour} 
                            onClick={() => this.props.onClick(this.props.letter)}>
                                {this.props.letter}
                </div>
        )
    }
}

export default Key
