import { useState } from "react";
import help1 from './assets/help-1.png';
import help2 from './assets/help-2.png';
import help3 from './assets/help-3.png';
import help4 from './assets/help-4.png';

function DisplayHelp() {
    const maxPages = 4;
    let [page, setPage] = useState(0);
    const content = [
        { 'text' : "To access your order details, please enter the Order ID provided to you via email. If you have any difficulties locating your Order ID, please refer to the email we sent you or contact our customer support for further assistance.", 'img' : help1},
        { 'text' : "On this page, please provide your initials as a way to identify yourself. For example, if your name is John Doe, you should enter \"JD\" as your initials. This helps us streamline the process and ensures a smooth experience for you. If you have any questions or need further guidance, please don't hesitate to reach out to our support team.", 'img' : help2},
        { 'text' : 'Please verify that the displayed name is correct. If it is not, contact support.\
        Read and follow the provided instructions to collect your order.\
        After collecting your order, select \"Confirm Collect\".\
        To start over or cancel, select \"Cancel\"\n\
        For any issues or questions, contact our support team.', 'img' : help3},
        { 'text' : "Please wait for confirmation that the collection process has been successfully processed. Once the confirmation is received, you can proceed with any further steps or actions as required.\
                    If you have any questions or concerns during the waiting period, please reach out to our support team for assistance. We appreciate your patience and cooperation.", 'img' : help4},
    ];
    const helpMessage = 'If you have any questions or concerns, please reach out to us at &nbsp;<strong>01908 850 691</strong>&nbsp;  or email &nbsp;<strong>info@shireswater.co.uk</strong>';
    function changePage(move) {
        setPage((page + move) % maxPages < 0 ? maxPages - 1 : (page + move) % maxPages );
    }

    return (
        <div className="flex flex-col font-sans h-full w-full content-between items-center m-4">
            <div className="flex flex-row w-[80%]">
                <div className="flex w-[50%] text-xs md:text-xl align-middle items-center text-center p-5">{content[page]['text']}</div>
                <div className="flex w-[50%] align-middle items-center content-center ">
                    <img className="flex object-contain h-[80%] shadow-lg rounded-xl" src={content[page]['img']} />
                </div>
            </div>
            <div className="flex justify-around p-3 bottom-0">
                <a href="#" onClick={ () => changePage(-1) } class="inline-flex items-center px-4 py-2 mr-10 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
                Previous
                </a>
                <a href="#" onClick={ () => changePage(+1) } class="inline-flex items-center px-8 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                Next
                <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
            </div>
            <div dangerouslySetInnerHTML={{ __html : helpMessage}} className="flex text-center text-gray-600 text-md md:text-lg">
                
            </div>
        </div>
    )
}

export default DisplayHelp;